<template>
  <b-row>
    <b-col
      :lg="field.cols ? field.cols : 2"
      v-for="field in fields"
      :key="field.key"
    >
      <slot :name="field.key" :field="field">
        <b-form-group :label="$t(field.label)" :label-for="field.key">
          <b-form-input
            :ref="field.key"
            v-if="field.type === 'text'"
            :id="field.key"
            v-model="data[field.key]"
            :name="field.key"
            :placeholder="$t(field.label)"
            @change="onChange"
          />
          <!--          <n-date-input-->
          <!--            :ref="field.key"-->
          <!--            v-if="field.type === 'date'"-->
          <!--            :id="field.key"-->
          <!--            v-model="data[field.key]"-->
          <!--            :name="field.key"-->
          <!--            @change="onChange"-->
          <!--          />-->

          <b-form-datepicker
            v-if="field.type === 'date'"
            :ref="field.key"
            v-model="data[field.key]"
            :name="field.key"
            class="mb-1"
            :max="field.noMaxDate ? null : new Date()"
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            }"
          />

          <n-async-single-select
            :ref="field.key"
            v-if="field.type === 'nAsynSingleSelection'"
            v-model="data[field.key]"
            :name="field.key"
            :init-options="data[field.key]"
            :repository="field.repository"
            :selection-key="field.selectionKey"
            :selection-label="field.selectionLabel"
            :clearable="field.clearable"
            @change="onChange"
          ></n-async-single-select>
          <n-single-select
            v-if="field.type === 'singleSelect'"
            v-model="data[field.key]"
            :options="field.options"
            :reduce="field.reduce"
            :clearable="field.clearable"
            :placeholder="field.placeholder"
          ></n-single-select>
        </b-form-group>
        <b-form-radio-group
          :ref="field.key"
          v-if="field.type === 'radio'"
          :id="field.key"
          v-model="data[field.key]"
          :disabled="field.disabled"
          @change="onChange"
        >
          <b-form-radio
            :value="option.value"
            v-for="option in field.options"
            :key="option.value"
            >{{ $t(option.text) }}</b-form-radio
          >
        </b-form-radio-group>
      </slot>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BFormDatepicker,
} from "bootstrap-vue";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import NSingleSelect from "@/components/NSingleSelect";
import NDateInput from "@/components/NDateInput";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    NAsyncSingleSelect,
    NDateInput,
    BFormDatepicker,
    NSingleSelect,
  },
  props: {
    value: {},
    fields: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      data: {},
    };
  },
  computed: {},
  mounted() {
    if (this.value) {
      this.data = this.value;
    }
  },
  methods: {
    reset() {
      this.fields.forEach((element) => {
        if (
          this.$refs[element.key] &&
          typeof this.$refs[element.key][0].reset === "function"
        ) {
          this.$refs[element.key][0].reset();
        }
        this.data[element.key] = null;
      });
    },
    onChange() {
      this.$emit("input", this.data);
    },
  },
};
</script>

<style scoped>
</style>
