<template>
  <cleave
    class="form-control"
    :value="value"
    :options="number"
    :raw="true"
    :disabled="disabled"
    :placeholder="placeholder"
    @input="input"
    @blur.native="blur"
  />
</template>

<script>
import Cleave from 'vue-cleave-component'

export default {
  components: {
    Cleave,
  },
  props: {
    placeholder: {},
    name: {},
    disabled: {},
    value: {},
    prefix: {},
    errors: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    }
  },
  methods: {
    input(value) {
      this.$emit('input', Number(value))
    },
    blur() {
      this.$emit('blur')
    },
  },
}
</script>

<style scoped>
</style>
