<template>
  <b-row>
    <b-col
      v-for="field in fields"
      :key="field.key"
      :sm="field.type === 'divider' || field.hide ? 12 : field.cols ? field.cols : 6"
      :class="{
        ...field.class,
        'h-0': field.hide
      }"
    >
      <div v-if="!field.resource || $can(field.action, field.resource)">
        <slot
          v-if="!field.hide"
          :name="field.key"
          :field="field"
        >
          <div
            v-if="field.type === 'divider'"
            class="d-flex mt-2"
          >
            <feather-icon
              v-if="field.icon"
              size="19"
              :icon="field.icon"
            />
            <h4 class="ml-50">
              {{ $t(field.label) }}
            </h4>
          </div>
          <b-form-group
            v-if="field.type !== 'divider'"
            :label-for="field.key"
            :description="getDesciption(field)"
            :class="field.class"
          >
            <validation-provider
              #default="{ errors }"
              :vid="field.key"
              :name="$t(field.label)"
              :rules="field.rules"
            >
              <label
                v-if="field.type !== 'checkbox' && !field.hideLabel"
              >{{ $t(field.label) }}
              </label>
              <b-form-input
                v-if="field.type === 'text'"
                v-model="data[field.key]"
                :placeholder="$t(field.label)"
                :name="field.key"
                :disabled="field.disabled"
                :class="errors.length > 0 ? 'is-invalid' : null"
                autocomplete="off"
                @input="onChange"
              />
              <n-password-input
                v-if="field.type === 'password'"
                :id="field.key"
                v-model="data[field.key]"
                :name="field.key"
                :placeholder="$t(field.label)"
                :errors="errors"
                :disabled="field.disabled"
                :class="errors.length > 0 ? 'is-invalid' : null"
                @input="onChange"
              />
              <n-currency-input
                v-if="field.type === 'currency'"
                :id="field.key"
                v-model="data[field.key]"
                :name="field.key"
                :placeholder="$t(field.label)"
                :errors="errors"
                :disabled="field.disabled"
                :class="errors.length > 0 ? ['is-invalid', field.class] : [field.class]"
                @input="onChange"
              />
              <n-tel-input
                v-if="field.type === 'tel'"
                :id="field.key"
                v-model="data[field.key]"
                :name="field.key"
                :placeholder="$t(field.label)"
                :errors="errors"
                :disabled="field.disabled"
                :class="errors.length > 0 ? 'is-invalid' : null"
                @input="onChange"
              />
              <b-form-radio-group
                v-if="field.type === 'radio'"
                :id="field.key"
                v-model="data[field.key]"
                :disabled="field.disabled"
                :class="errors.length > 0 ? 'is-invalid' : null"
                @change="onChange"
              >
                <b-form-radio
                  v-for="option in field.options"
                  :key="option.value"
                  :value="option.value"
                >{{ $t(option.text) }}</b-form-radio>
              </b-form-radio-group>
              <b-form-checkbox
                v-if="field.type === 'checkbox'"
                :id="field.key"
                v-model="data[field.key]"
                :name="field.key"
                :value="true"
                :unchecked-value="false"
                :disabled="field.disabled"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                {{ $t(field.label) }}
              </b-form-checkbox>
              <n-single-image-uploader
                v-if="field.type === 'single-image'"
                v-model="data[field.key]"
                :pw="field.pw"
                :ph="field.ph"
                :full-width="field.fullWidth"
                :image="initValue[field.initKey]"
                :readonly="field.disabled"
                :class="errors.length > 0 ? 'is-invalid' : null"
                @change="onChange"
              />
              <b-form-textarea
                v-if="field.type === 'textarea'"
                :id="field.key"
                v-model="data[field.key]"
                :placeholder="$t(field.label)"
                :rows="field.rows ? field.rows : 2"
                :max-rows="field.maxRows ? field.maxRows : 6"
                :readonly="field.disabled"
                :disabled="field.disabled"
                :class="errors.length > 0 ? 'is-invalid' : null"
                @input="onChange"
              />
              <n-multi-select
                v-if="field.type === 'NMultiSelection'"
                v-model="data[field.key]"
                :name="field.key"
                :init-options="data[field.key]"
                :options="field.options"
                :selection-key="field.selectionKey"
                :selection-label="field.selectionLabel"
                :readonly="field.disabled"
                :class="errors.length > 0 ? 'is-invalid' : null"
                @input="onChange"
              />
              <n-async-multi-select
                v-if="field.type === 'nAsynMultiSelection'"
                v-model="data[field.key]"
                :name="field.key"
                :init-options="data[field.key]"
                :repository="field.repository"
                :selection-key="field.selectionKey"
                :selection-label="field.selectionLabel"
                :readonly="field.disabled"
                :class="errors.length > 0 ? 'is-invalid' : null"
                @input="onChange"
              />
              <n-async-single-select
                v-if="field.type === 'nAsynSingleSelection'"
                v-model="data[field.key]"
                :name="field.key"
                :init-options="data[field.key]"
                :repository="field.repository"
                :selection-key="field.selectionKey"
                :selection-label="field.selectionLabel"
                :readonly="field.disabled"
                :class="errors.length > 0 ? 'is-invalid' : null"
                @input="onChange"
              />
              <n-single-select
                v-if="field.type === 'nSingleSelection'"
                v-model="data[field.key]"
                :name="field.key"
                :init-options="data[field.key]"
                :options="field.options"
                :selection-key="field.selectionKey"
                :selection-label="field.selectionLabel"
                :translate="field.translate"
                :reduce="field.reduce"
                :readonly="field.disabled"
                :class="errors.length > 0 ? 'is-invalid' : null"
                @input="onChange"
              />
              <n-date-picker
                v-if="field.type === 'date'"
                :ref="field.key"
                v-model="data[field.key]"
                :name="field.key"
                :disable-type="field.disableType"
                :class="errors.length > 0 ? 'is-invalid' : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </slot>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormTextarea,
  BRow,
} from 'bootstrap-vue'
import NPasswordInput from '@/components/NPasswordInput.vue'
import NAsyncMultiSelect from '@/components/NAsyncMultiSelect.vue'
import NMultiSelect from '@/components/NMultiSelect.vue'
import NAsyncSingleSelect from '@/components/NAsyncSingleSelect.vue'
import NSingleImageUploader from '@/components/NSingleImageUploader.vue'
import NCurrencyInput from '@/components/NCurrencyInput.vue'
import NTelInput from '@/components/NTelInput.vue'
import NSingleSelect from '@/components/NSingleSelect.vue'
import NDatePicker from '@/components/NDatePicker.vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    NPasswordInput,
    BFormRadioGroup,
    BFormRadio,
    NSingleImageUploader,
    BRow,
    BCol,
    BFormTextarea,
    NAsyncMultiSelect,
    NAsyncSingleSelect,
    BFormCheckbox,
    NCurrencyInput,
    NTelInput,
    NMultiSelect,
    NSingleSelect,
    NDatePicker,
  },
  props: {
    value: {},
    initValue: {
      type: Object,
      default() {
        return {}
      },
    },
    fields: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {},
  watch: {
    data(newValue) {
      this.$emit('input', newValue)
    },
    initValue(newValue) {
      this.data = {
        ...this.data,
        ...newValue,
      }
    },
  },
  mounted() {
    this.data = {
      ...this.value,
    }
  },
  methods: {
    setValue(value) {
      this.data = {
        ...value,
      }
    },
    getDesciption(field) {
      if (field.type === 'single-image') {
        return this.$t('general.recommendDimension', {
          ph: field.ph,
          pw: field.pw,
        })
      }
      return ''
    },
    isOptional(rules) {
      return !rules.includes('required')
    },
    onChange() {
      this.$emit('input', this.data)
    },
  },
}
</script>

<style scoped>
</style>
