export default [

  {
    key: 'type',
    label: 'field.type',
    rules: 'required',
    type: 'radio',
    options: [
      { text: 'field.deposit', value: 'deposit', class: 'text-wala' },
      { text: 'field.withdraw', value: 'withdrawal', class: 'text-meron' },
    ],
  },
  {
    key: 'amount',
    label: 'field.amount',
    rules: 'required|decimal:2|min_value:0.01',
    type: 'currency',
    class: 'amount-input',
    cols: 12,
  },
  {
    key: 'promoAmount',
    label: 'field.promotionAmount',
    rules: 'required|decimal:2|min_value:0.00',
    type: 'currency',
    cols: 12,
  },
  {
    key: 'remark',
    label: 'field.remark',
    rules: '',
    type: 'textarea',
    cols: 12,
    row: 1,
  },
]
