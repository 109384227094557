<template>
  <div v-if="$can('read', 'update-channel-bet-setting')">
    <b-button variant="primary" @click="show">
      {{ $t("breadcrumb.betSetting") }}
    </b-button>
    <b-modal
      hide-footer
      centered
      no-close-on-backdrop
      size="xl"
      v-model="isShow"
      :title="$t('breadcrumb.betSetting')"
    >
      <validation-observer ref="form" #default="{ invalid }">
        <n-form-confirmation
          ref="confirmForm"
          key="form"
          @submit="save"
          :form="$refs.form"
          :disabled="invalid"
        >
          <n-input :key="`input-${key}`" :fields="fields" v-model="data">
            <template #userIds="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-async-multi-select
                    :key="`multi-select-${userKey}`"
                    ref="userIds"
                    v-model="data[item.field.key]"
                    :name="item.field.key"
                    :init-options="data[item.field.key]"
                    :repository="item.field.repository"
                    :selection-key="item.field.selectionKey"
                    :selection-label="item.field.selectionLabel"
                    :readonly="!data.ccy"
                    :ccy="data.ccy"
                    :typeId="data.typeId"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  ></n-async-multi-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="button"
                variant="primary"
                :loading="loading"
                @submit="submit"
              >
                {{ $t("button.save") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BRow, BCol, BButton, BFormGroup } from "bootstrap-vue";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";
import NButtonLoading from "@/components/NButtonLoading.vue";
import FormInput from "./formInput";
import CompanyFormInput from "./companyFormInput";
import Repository from "@/repositories/RepositoryFactory";
import NAsyncMultiSelect from "@/components/NAsyncMultiSelect";

const MemberRepository = Repository.get("member");

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    NInput,
    NFormConfirmation,
    NButtonLoading,
    NAsyncMultiSelect,
  },
  props: {
    typeId: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    fields() {
      if (this.typeId == 2) {
        return CompanyFormInput;
      }

      return FormInput;
    },
  },
  data() {
    return {
      key: 1,
      userKey: 1,
      isShow: false,
      loading: false,
      data: {
        ccy: 116,
        typeId: 2,
        type: 0,
        userIds: [],
      },
    };
  },
  watch: {
    "data.ccy"() {
      this.data.userIds = [];
      this.key++;
    },
  },
  methods: {
    show() {
      this.data = {
        ccy: 116,
        typeId: this.typeId,
        type: 0,
        userIds: [],
      };
      this.isShow = true;
      this.key++;
    },
    hide() {
      this.isShow = false;
      this.loading = false;
    },
    stopLoading() {
      this.loading = false;
    },
    submit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.$refs.confirmForm.confirm();
        }
      });
    },
    save() {
      this.loading = true;
      MemberRepository.saveBetSetting(this.data)
        .then((response) => {
          this.$emit("save");
          this.hide();
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.form.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
